<template>
  <div class="drag-drop">
    <div
      :class="{ 'uploader-box uploader': true, 'active-drop': dropOver }"
      style=""
      v-show="enabled"
    >
      <div class="image-placeholder add-slide-box">
        <i
          :class="'add-slide-icon ' + (initialIconClass || 'fa fa-plus-circle')"
          v-if="initialState"
        ></i>
        <i class="fas fa-cloud-upload-alt add-slide-icon" v-if="dragFile"></i>
        <i class="fas fa-cog fa-spin add-slide-icon" v-if="processing"></i>
      </div>
      <div
        class="uploader-box uploader-overlay"
        :id="dropzoneId"
        v-show="!internalLoading && !loading"
      ></div>
    </div>
    <div
      class="previewImage"
      v-for="img in imagesPreview"
      :key="img.thumb"
      v-show="!imageLoadError"
    >
      <a
        :href="img.link"
        style="cursor: pointer"
        class="link-image"
        target="_blank"
      >
        <img
          :src="img.thumb"
          @error="handleImageError"
          @load="handleImageLoad"
        />
      </a>
      <a @click="removeImage(img.link)" class="remove-image">
        <i class="fas fa-trash"></i>
      </a>
    </div>
  </div>
</template>

<script>
import Dropzone from 'dropzone'
import ApiService from '../service/api-web'
import * as _ from 'lodash'
export default {
  name: 'DragDropUploader',
  props: {
    uploadUrl: {
      type: String,
      required: true
    },
    uploadMultiple: {
      type: Boolean,
      default: false,
      required: false
    },
    value: {
      type: Array,
      default: () => [],
      required: false
    },
    params: {
      type: Object,
      default: () => {},
      required: false
    },
    initialIconClass: {
      type: String,
      default: '',
      required: ''
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.previewImage = Array.isArray(newVal) ? newVal : [newVal]
        }
      }
    },
    uploadUrl: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.dropzoneInstance.options.url = `${process.env
            .VUE_APP_LEGACY_URL || ''}${newVal}`
        }
      }
    }
  },
  data: () => ({
    dropzoneId: 'dropzone-' + Math.ceil(Math.random() * 1000000),
    dropzoneInstance: null,
    dropOver: false,
    internalLoading: false,
    enabled: true,
    imageLoadError: false
  }),
  methods: {
    handleImageError() {
      this.imageLoadError = true
    },
    handleImageLoad() {
      this.imageLoadError = false
    },
    removeImage(image) {
      this.$emit('remove', image)
    }
  },
  computed: {
    initialState: function() {
      return (
        !this.dropOver && !this.internalLoading && !this.loading && this.enabled
      )
    },
    dragFile: function() {
      return (
        this.dropOver && !this.internalLoading && !this.loading && this.enabled
      )
    },
    processing: function() {
      return this.loading || (this.internalLoading && this.enabled)
    },
    unavailable: function() {
      return !this.enabled
    },
    imagesPreview() {
      const images = Array.isArray(this.value) ? this.value : [this.value]
      return images.map(item => ({
        thumb: item.mediumSize || item.smallSize || item.largeSize || '',
        link: item.mediumSize || item.largeSize || item.smallSize || ''
      }))
    }
  },
  mounted() {
    this.dropzoneInstance = new Dropzone('#' + this.dropzoneId, {
      url: `${process.env.VUE_APP_LEGACY_URL || ''}${this.uploadUrl}`,
      params: this.params || {},
      uploadMultiple: this.uploadMultiple || false,
      acceptedFiles: 'image/*',
      createImageThumbnails: false,
      maxFiles: 5,
      headers: ApiService.headers || {},
      maxFilesize: 5,
      parallelUploads: 10
    })

    this.dropzoneInstance.on('addedfile', () => {
      this.dropzoneInstance.files.sort(function(a, b) {
        return a.name.localeCompare(b.name)
      })
    })

    this.dropzoneInstance.on('sending', () => {
      this.dropOver = false
      this.$emit('init-upload', true)
      this.internalLoading = true
    })

    this.dropzoneInstance.on('queuecomplete', () => {
      this.dropOver = false
      this.internalLoading = false
    })

    this.dropzoneInstance.on('successmultiple', (file, response) => {
      this.$emit('upload-success', { file: file, response: response })
      this.dropzoneInstance.removeFile(file)
      this.dropOver = false
      this.handleImageLoad()
    })

    this.dropzoneInstance.on('success', (file, response) => {
      this.$emit('upload-success', {
        file: file,
        images: response.images,
        response: response
      })

      this.$emit('input', response.images)
      this.dropzoneInstance.removeFile(file)
      this.dropOver = false
      this.handleImageLoad()
    })

    this.dropzoneInstance.on('error', err => {
      let message = ''
      const jsonResponse = JSON.parse(_.get(err, 'xhr.response', '{}'))
      const quantidadeDeImagens = this.dropzoneInstance.files.length > 5

      message =
        err.size / (1024 * 1024) > 1
          ? 'Arquivo maior que limite permitido! Por favor envie um arquivo de no máximo 1mb'
          : quantidadeDeImagens
          ? 'Ops, você atingiu o limite de 5 imagens por vez. Não se preocupe, elas ainda serão processadas.'
          : _.get(jsonResponse, 'message', 'Falha ao fazer upload da imagem')

      this.$vueOnToast.pop('error', message)
      this.handleImageError()
    })
    this.dropzoneInstance.on('dragover', () => {
      this.dropOver = true
    })
    this.dropzoneInstance.on('dragleave', () => {
      this.dropOver = false
    })
  }
}
</script>

<style lang="scss">
.drag-drop {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .add-slide-box {
    box-sizing: border-box;
    border: 2px dashed #ccc;
    border-radius: 1em;
    line-height: 6em;
    width: 140px;
    height: 200px;
    background: rgba(239, 239, 239, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .add-slide-icon {
    font-size: 5rem;
    color: #6a6c6f;
  }
  .uploader-box {
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    margin: 2px;
    cursor: pointer;
    &:hover {
      transform: scale(1.07);
    }
  }

  .uploader-overlay * {
    visibility: hidden;
  }

  .uploader-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .uploader-box.uploader.active-drop * {
    color: #3497dc;
    border-color: #3497dc;
  }

  .action-overlay {
    width: 100%;
    padding-top: 0;
    color: #6c6c6c;
    top: 1px;
    float: right;
    text-align: right;
    padding-right: 10px;
  }

  .drag-target-position {
    display: inline-block;
    min-height: 2em;
    border-radius: 10px;
    float: left;
    height: 100px;
    width: 13px;
    margin-left: -10px;
    margin-right: -10px;
    color: transparent;
    border: 1px solid white;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .drag-target-position.over {
    transform: scaleX(1.1);
    width: 2em;
    border: 1px dashed;
    color: #3497dc;
    box-shadow: inset 1px 1px 20px 3px #c7e8ff, inset 1px 1px 20px 20px #f1f9ff;
    padding-top: 0.5em;
    font-size: 4rem;
    padding-bottom: 1em;
    border: 1px dashed;
  }
  .previewImage {
    width: 140px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #ccc;
    margin-top: 1em;
    border-radius: 1em;
    margin: 2px;
    position: relative;
    a.link-image {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
      }
    }
    a.remove-image {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 5px;
      right: 5px;
      color: var(--auxiliar-color);
      transition: all 0.3s ease-in-out;
      &:hover {
        color: var(--danger-color);
      }
    }
  }
}
</style>
