<template>
  <div>
    <FormAutocomplete
      :label="label"
      :placeholder="placeholder"
      :dense="dense"
      :solo="solo"
      :disabled="disabled"
      :readonly="readonly"
      :clearable="clearable"
      :no-filter="false"
      :chips="multiple"
      :validation="validation"
      :multiple="multiple"
      :options="options"
      :loading="loading"
      @search="onSearch"
      @change="onChange"
      v-model="selected"
      useTemplate
      return-object
    >
      <template v-slot:item="{ item }">
        <slot name="item" :item="item">
          {{ item.text }}
        </slot>
      </template>
    </FormAutocomplete>
    <section class="mb-8" v-if="order">
      <h3>Arraste para ordenar as tags</h3>
      <draggable v-model="selected" @start="drag = true" @end="onEnd">
        <transition-group
          type="transition"
          name="flip-list"
          class="containerListProduct"
        >
          <div
            class="containerSort"
            v-for="item in selected"
            :key="item.idtagpesquisa"
            :id="item.idtagpesquisa"
          >
            <div class="sortable">
              {{ item.text }}
            </div>
          </div>
        </transition-group>
      </draggable>
    </section>
  </div>
</template>

<script>
import FormAutocomplete from '@/components/form/FormAutocomplete'
import ApiWeb from '../../service/api-web'
import * as _ from 'lodash'
import draggable from 'vuedraggable'

export default {
  name: 'FormTag',
  components: { FormAutocomplete, draggable },
  props: {
    value: {
      type: [Array, Object],
      default: () => [],
      required: false
    },
    label: {
      type: String,
      default: '',
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    dense: {
      type: Boolean,
      default: true,
      required: false
    },
    solo: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false
    },
    clearable: {
      type: Boolean,
      default: false,
      required: false
    },
    endpoint: {
      type: String,
      required: true
    },
    keyid: {
      type: String,
      default: 'id',
      required: false
    },
    keyvalue: {
      type: String,
      default: 'name',
      required: false
    },
    queryname: {
      type: String,
      default: 'search',
      required: false
    },
    multiple: {
      type: Boolean,
      default: true,
      required: false
    },
    searchWithSpaces: {
      type: Boolean,
      default: false,
      required: false
    },
    validation: {
      type: Object,
      default: () => null,
      required: false
    },
    order: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data: () => ({
    loading: false,
    resultSearch: [],
    selected: []
  }),
  computed: {
    options() {
      return (this.resultSearch || []).map(item => ({
        ...item,
        text: item[this.keyvalue],
        value: item[this.keyid]
      }))
    }
  },
  watch: {
    value: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.initModel(newVal)
        }
      }
    }
  },
  created() {
    this.initModel(this.value)
    if (this.searchWithSpaces === null) {
      this.searchWithSpaces = true
    }
  },
  methods: {
    initModel(value) {
      if (value) {
        if (Array.isArray(value)) {
          this.selected = (value || []).map(item => ({
            ...item,
            text: item[this.keyvalue],
            value: item[this.keyid]
          }))
        } else {
          this.selected = {
            ...value,
            text: value[this.keyvalue],
            value: value[this.keyid]
          }
        }
      }
      this.onChangeExternalValue()
    },
    onChangeExternalValue() {
      const itemsSelected = Array.isArray(this.selected)
        ? this.selected
        : [this.selected]
      let idtags = itemsSelected.reduce((tags, tag) => {
        let tagId = _.get(tag, this.keyid)
        if (!_.find(this.resultSearch, [this.keyid, tagId])) {
          tags.push(tagId)
        }
        return tags
      }, [])
      if (idtags.length > 0) {
        const chunks = _.chunk(idtags, 50)
        for (let i in chunks) {
          const chunk = chunks[i]
          this.onSearch(chunk, true)
        }
      }
    },
    onSearch(value) {
      this.loading = true
      let arrayValues = Array.isArray(value) ? value : [value]
      const initialQuery = this.endpoint.split('?')
      let queryParms = arrayValues.reduce((query, val) => {
        if (query) query += '&'
        query += `${this.queryname}=${val}`
        return query
      }, _.get(initialQuery, '1', ''))
      ApiWeb.api
        .get(`${initialQuery[0]}?${queryParms}`)
        .then(resp => {
          // o componente de autocomplete não filtra corretamente buscas por "frases ou palavras compostas" (ex: nome de produtos)
          if (!this.searchWithSpaces) {
            this.resultSearch = _.uniqBy(
              this.resultSearch.concat(resp.data),
              this.keyid
            )
          } else {
            this.resultSearch = this.resultSearch.concat(resp.data)
          }
        })
        .finally(() => (this.loading = false))
    },
    onChange(value) {
      this.$emit('input', value)
      this.$emit('change', value)
    },
    onEnd() {
      this.selected = this.selected.map((item, i) => ({
        ...item,
        ordem: i + 1,
        text: item[this.keyvalue],
        value: item[this.keyid]
      }))
      this.initModel(this.selected)
      this.onChange(this.selected)
    }
  }
}
</script>

<style lang="scss">
.containerListProduct {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
}

.containerSort {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  border-radius: 30px;
  border: 1px solid #ccc;

  .sortable {
    text-align: center;
    width: 100%;
    padding: 0.7em;
    cursor: move;
  }
}

.flip-list-move {
  transition: transform 0.5s;
}
</style>
