<template>
  <div class="line-divider">
    <v-divider v-if="position == 'top'"></v-divider>
    <v-row class="row-line-divider" :justify="justify" :align="align">
      <slot></slot>
    </v-row>
    <v-divider v-if="position == 'bottom'"></v-divider>
  </div>
</template>

<script>
export default {
  name: 'LineDivider',
  props: {
    position: {
      type: String,
      default: 'top',
      required: false
    },
    justify: {
      type: String,
      default: 'center',
      required: false
    },
    align: {
      type: String,
      default: 'center',
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.row-line-divider {
  padding-top: 10px;
  padding-bottom: 20px;
}
.line-divider {
  padding-top: 10px;
}
</style>
