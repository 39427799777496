<template> <vue-simplemde v-model="content" :configs="config"/></template>

<script>
import VueSimplemde from 'vue-simplemde'

export default {
  name: 'FormMarkdown',
  props: {
    value: {
      type: String,
      default: '',
      required: true
    }
  },
  data: () => ({
    config: {
      toolbar: [
        'bold',
        'italic',
        'strikethrough',
        '|',
        'heading',
        'heading-smaller',
        'heading-bigger',
        'heading-1',
        'heading-2',
        'heading-3',
        '|',
        'unordered-list',
        'ordered-list',
        'quote',
        '|',
        'link',
        'image',
        'table',
        'horizontal-rule',
        'preview'
      ],
      spellChecker: false
    },
    content: ''
  }),
  components: {
    VueSimplemde
  },
  watch: {
    content: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.$emit('input', newVal)
          this.$emit('change', newVal)
        }
      }
    },
    value: {
      handler(newVal, oldVal) {
        if (newVal != oldVal && newVal != this.content) {
          this.content = newVal
        }
      }
    }
  },
  created() {
    this.content = this.value || ''
  }
}
</script>

<style>
@import '~simplemde/dist/simplemde.min.css';
.editor-statusbar {
  display: none;
}
</style>
