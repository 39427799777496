<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    @submit="onSubmit"
    :loading="isLoading"
    routeback="/fornecedor/comercial/conjunto-produtos"
  >
    <LineDivider><h4>Dados básicos</h4></LineDivider>
    <FormField
      label="Código de barras"
      placeholder="(opcional)"
      v-model="form.codigobarras"
      :disabled="disabledCodBarras"
      :validation="$v.form.codigobarras"
      @blur="pesquisarCodigoBarrasExistente"
      hint="(identificador da indústria na embalagem do conjunto)"
    />
    <FormField
      label="Nome"
      v-model="form.descricao"
      :validation="$v.form.descricao"
    />
    <FormField
      label="Código interno"
      v-model="form.id_produto_erp"
      :validation="$v.form.id_produto_erp"
      hint="(código do conjunto utilizado no seu sistema de emissor de nota fiscal)"
    />
    <v-row no-gutters>
      <FormSwitch v-model="form.disponivel" label="Disponível para venda" />
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" class="ml-3 mt-n2">far fa-question-circle</v-icon>
        </template>
        O conjunto ficará disponível apenas quanto todos os produtos dentro do
        conjunto estiverem disponíveis.
      </v-tooltip>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12"><b>Tipo de venda</b></v-col>
      <v-col cols="12" xs="12" sm="6" md="4" lg="auto" class="mr-lg-5"
        ><FormCheckbox
          label="Avulso"
          val="AVULSO"
          v-model="form.tag"
          :disabled="form.tag.includes('SOB_DEMANDA')"
        />
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="4" lg="auto" class="mr-lg-5"
        ><FormCheckbox
          label="Recorrência"
          val="RECORRENCIA"
          v-model="form.tag"
          :disabled="form.tag.includes('SOB_DEMANDA')"
        />
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="4"
        lg="auto"
        class="mr-lg-5"
      >
        <v-row no-gutters>
          <FormCheckbox
            label="Sob Demanda"
            val="SOB_DEMANDA"
            v-model="form.tag"
            :disabled="
              form.tag.includes('AVULSO') ||
                form.tag.includes('RECORRENCIA') ||
                (!enableVendaMais && !form.tag.includes('SOB_DEMANDA'))
            "
          />
          <v-tooltip top v-if="!enableVendaMais">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" class="ml-3 mt-n2"
                >far fa-question-circle</v-icon
              >
            </template>
            O tipo de venda Sob Demanda tem como requisito a contratação do
            módulo Venda Mais, para mais informações entre em contato com o
            setor comercial.
          </v-tooltip>
        </v-row>
      </v-col>
    </v-row>
    <FormField
      label="Código agrupador de embalagem"
      placeholder="(Opcional)"
      v-model="form.agrupador_embalagem"
    />
    <FormField
      label="Descrição curta sobre a embalagem"
      placeholder="(Opcional)"
      v-model="form.descricao_embalagem"
    />
    <div v-if="enableCommerce">
      <FormField
        v-if="enableMaxLimitControl"
        label="Máximo permitido por pedido"
        v-model="form.max_por_pedido"
        :money="form.max_por_pedido ? moneyMask : null"
        help="Informe qual quantidade máxima deste conjunto dentro do pedido."
      />
      <FormSwitch
        v-if="enableMaxLimitControl"
        v-model="form.permitir_alteracao_campos_erp"
        label="Permitir que a integração modifique o máximo por pedido"
        val="max_por_pedido"
      />
      <FormField
        v-if="enableMinLimitControl"
        label="Mínimo permitido por pedido"
        v-model="form.min_por_pedido"
        :money="form.min_por_pedido ? moneyMask : null"
        help="Informe qual quantidade mínima deste conjunto dentro do pedido."
      />
      <FormSwitch
        v-if="enableMinLimitControl"
        v-model="form.permitir_alteracao_campos_erp"
        label="Permitir que a integração modifique o mínimo por pedido"
        val="min_por_pedido"
      />
    </div>
    <p>
      <b>Detalhes do conjunto</b> (<a
        href="https://pt.wikipedia.org/wiki/Markdown"
        target="_blank"
        >dicas de formatação</a
      >)
    </p>
    <FormMarkdown v-model="form.descricao_longa" />
    <LineDivider><h4>SEO</h4></LineDivider>
    <FormField
      label="URL amigável"
      v-model="form.slug"
      help="URL usada para acessar o detalhamento do conjunto. Dê preferência a URLs curtas e diretas que facilitem a visualização e digitação"
      v-stringscaped
    />
    <FormField
      label="Descrição curta"
      v-model="form.metatags_seo.description"
      help="Máximo 160 caracteres sobre o conjunto, ela é utilizada por ferramentas de busca online do conjunto."
      counter
      maxLength="160"
    />
    <FormField
      label="Palavras-chave"
      v-model="form.metatags_seo.keywords"
      help="Máximo 160 caracteres. Separadas por vírgula. Estas palavras são utilizadas pelas ferramentas de busca online do conjunto."
      counter
      maxLength="160"
    />
    <LineDivider><h4>Imagens do conjunto</h4></LineDivider>
    <DragDropUploader
      v-model="form.images"
      :upload-url="uploadUrl"
      :upload-multiple="true"
      :loading="imageLoading"
      initial-icon-class="fas fa-cloud-upload-alt"
      @remove="removeImage"
    >
    </DragDropUploader>
    <LineDivider><h4>Categorias do conjunto</h4></LineDivider>
    <ArvoreCategoria v-model="form.setores" />
    <LineDivider><h4>Tags do conjunto</h4></LineDivider>
    <FormTags
      placeholder="Pesquise e adicione tags"
      v-model="form.tag_pesquisa"
      :endpoint="endpointTags"
      keyid="idtagpesquisa"
      keyvalue="valor"
      :search-with-spaces="true"
    >
      <template v-slot:item="{ item }">
        <div class="d-flex flex-column align-start">
          <p style="margin-bottom:0!important">{{ item.text }}</p>
          <small v-if="item.small">Cod.: {{ item.descricao }}</small>
          <small v-if="item.agrupadores"
            >Agr.:{{ item.agrupadores.join('|') }}</small
          >
        </div>
      </template>
    </FormTags>
    <LineDivider>
      <h4>Segmento de clientes</h4>
    </LineDivider>
    <FormTags
      placeholder="Pesquise e adicione segmento de clientes"
      v-model="form.segmentoscliente"
      :endpoint="endpointSegmentoCliente"
      keyid="idsegmentocliente"
      keyvalue="nome"
      :search-with-spaces="true"
    >
      <template v-slot:item="{ item }">
        <div class="d-flex flex-column align-start">
          <p style="margin-bottom:0!important">{{ item.nome }}</p>
        </div>
      </template>
    </FormTags>
    <div v-if="enableCommerce">
      <LineDivider><h4>Dados de transporte</h4></LineDivider>
      <FormField
        label="Altura (m)"
        v-model="form.altura"
        :money="moneyMask"
        @blur="validatePositiveNumber($event, 'altura')"
      />
      <FormField
        label="Largura (m)"
        v-model="form.largura"
        :money="moneyMask"
        @blur="validatePositiveNumber($event, 'largura')"
      />
      <FormField
        label="Profundidade (m)"
        v-model="form.comprimento"
        :money="moneyMask"
        @blur="validatePositiveNumber($event, 'comprimento')"
      />
      <FormField
        label="Peso (kg)"
        v-model="form.peso"
        :money="moneyMask"
        @blur="validatePositiveNumber($event, 'peso')"
      />
      <FormField
        label="Diâmetro"
        v-model="form.diametro"
        :money="moneyMask"
        @blur="validatePositiveNumber($event, 'diametro')"
      />
    </div>
    <LineDivider><h4>Adicionar produtos ao conjunto</h4></LineDivider>
    <FormSwitch
      v-model="form.mostrarPrecoSubItens"
      label="Mostrar preço dos produtos"
    />
    <v-row>
      <v-col cols="6">
        <FormTags
          :placeholder="'Pesquise e selecione os produtos'"
          :label="'Produto'"
          v-model="subItem"
          :endpoint="endpointProdutos"
          queryname="filter"
          :searchWithSpaces="true"
          keyid="id_produto_erp"
          keyvalue="text"
          :multiple="false"
          :clearable="true"
        >
          <template v-slot:item="{ item }">
            <div class="d-flex flex-column align-start">
              <p style="margin-bottom:0!important">{{ item.nome }}</p>
              <small v-if="item.id_produto_erp"
                >Cód. interno: {{ item.id_produto_erp }}</small
              >
              <small v-if="item.codigobarras"
                >Cód. barras:{{ item.codigobarras }}</small
              >
            </div>
          </template>
        </FormTags>
      </v-col>
      <v-col cols="2">
        <FormField
          label="Quantidade"
          typeField="number"
          v-model="subItem.quantidade_itens"
          @blur="normalizeQuantity"
        />
      </v-col>
      <v-col cols="2">
        <FormField
          label="Fator preço"
          typeField="number"
          v-model="subItem.indice_preco"
          @blur="normalizePricefactor"
        />
      </v-col>
      <v-col cols="2">
        <FormButton type="success" @click="addProduct(subItem)">
          Adicionar Item
        </FormButton>
      </v-col>
    </v-row>
    <v-row v-if="showSubItens">
      <v-col>
        <v-simple-table>
          <thead>
            <tr>
              <th>Nome do produto</th>
              <th class="text-center">Quantidade</th>
              <th class="text-center">Fator preço</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in form.subItens" :key="item.idvinculoconjunto">
              <td>{{ item.nome }}</td>
              <td class="text-center">
                {{ item.quantidade_itens }}
              </td>
              <td class="text-center">
                {{ item.indice_preco }}
              </td>
              <td class="text-center">
                <FormButton type="error" @click="removeSubItem(item)">
                  Remover
                </FormButton>
              </td>
            </tr>
            <tr v-for="item in newSubItens" :key="item.idcadastroextraproduto">
              <td>{{ item.nome }}</td>
              <td class="text-center">{{ item.quantidade_itens }}</td>
              <td class="text-center">{{ item.indice_preco }}</td>
              <td class="text-center">
                <FormButton type="error" @click="removeNewSubItem(item)">
                  Remover
                </FormButton>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import FormField from '@/components/form/FormField'
import FormButton from '@/components/form/FormButton'
import ArvoreCategoria from '../../cadastro/produto/ArvoreCategoria'
import LineDivider from '@/components/LineDivider'
import { required, numeric } from 'vuelidate/lib/validators'
import {
  PRODUTO_CREATE,
  PRODUTO_LOAD,
  PRODUTO_UPDATE,
  PRODUTO_LOAD_BY_COD_BARRAS,
  PRODUTO_LOAD_IMAGES,
  PRODUTO_REMOVE_IMAGE,
  PRODUTO_REMOVE_SUBITEM
} from '@/store/actions/produto'
import FormSwitch from '@/components/form/FormSwitch'
import FormCheckbox from '@/components/form/FormCheckbox'
import FormTags from '@/components/form/FormTags'
import { mapGetters } from 'vuex'
import DragDropUploader from '@/components/Drag-drop-uploader'
import FormMarkdown from '@/components/form/FormMarkdown'
import * as _ from 'lodash'
import { ModulesEnum } from '@/store/actions/modulesEnum'

export default {
  name: 'ConjuntoForm',
  components: {
    SidePopup,
    FormButton,
    ArvoreCategoria,
    LineDivider,
    FormField,
    FormSwitch,
    FormCheckbox,
    FormTags,
    DragDropUploader,
    FormMarkdown
  },
  data: () => ({
    mode: 'add',
    subItem: {},
    newSubItens: [],
    form: {
      images: [],
      codigobarras: null,
      id_produto_erp: '',
      descricao: '',
      descricoes_adicionais: [],
      unidade: 'UN',
      idProduto: null,
      setores: [],
      privado: false,
      label_unidade: '',
      label_unidade_multiplo: '',
      multiplo_incremento: 0,
      descricao_embalagem: '',
      descricao_longa: '',
      imagem_principal: '',
      disponivel: false,
      ativo: true,
      permite_alteracao_erp: true,
      permitir_alteracao_campos_erp: [],
      ignorar_alteracao_erp: [],
      granularidade: 1,
      hasLogIntegracao: false,
      peso: 0,
      altura: 0,
      largura: 0,
      diametro: 0,
      comprimento: 0,
      previewImage: '',
      preco_embalagem: null,
      permite_embalagem: false,
      ocultar_busca_principal: false,
      metatags_seo: {
        description: '',
        keywords: ''
      },
      slug: '',
      quantidade_subitem_embalagem: '',
      tag: ['AVULSO'],
      tag_pesquisa: [],
      caracteristicas: [],
      formasPagamentoCustomizadas: [],
      camposIgnoraveisIntegracao: ['max_por_pedido', 'min_por_pedido'],
      maximo_disponivel: null,
      max_por_pedido: null,
      min_por_pedido: null,
      carrossel: [],
      segmentoscliente: [],
      subItens: []
    },
    moneyMask: {
      decimal: ',',
      thousands: ' ',
      prefix: '',
      suffix: '',
      default: '',
      precision: 3,
      masked: false
    },
    isLoading: false,
    imageLoading: false
  }),
  validations: {
    form: {
      descricao: { required },
      codigobarras: { numeric },
      id_produto_erp: { required }
    }
  },
  methods: {
    getDataFormasPagamentosCustomizadas(formas) {
      const formasPagamento = []
      if (formas) {
        const _formas = Object.values(formas)
        if (_formas && _formas.length) {
          _formas.forEach(ff => {
            formasPagamento.push({
              ...ff,
              id: `${ff.idformapagto}-${Math.random() * 1000}`
            })
          })
        }
      }
      return formasPagamento
    },
    getDataSubmitFormasPagamento(formas) {
      return (
        (formas &&
          formas.map(f => {
            return { ...f, fator: Number(f.fator) }
          })) ||
        null
      )
    },
    getData(idproduto, idcadastroextraproduto) {
      this.isLoading = true
      this.$store
        .dispatch(PRODUTO_LOAD, { idproduto, idcadastroextraproduto })
        .then(produto => {
          this.form = {
            ...this.form,
            ...produto,
            disponivel: produto.disponivel,
            mostrarPrecoSubItens: !produto.esconder_preco_sub_itens,
            descricoes_adicionais: produto.descricoes_adicionais || [],
            unidade: produto.tipounidade,
            granularidade: this.prepareNumberDecimalToString(
              produto.granularidade
            ),
            peso: this.prepareNumberDecimalToString(produto.peso),
            altura: this.prepareNumberDecimalToString(produto.altura),
            largura: this.prepareNumberDecimalToString(produto.largura),
            diametro: this.prepareNumberDecimalToString(produto.diametro),
            comprimento: this.prepareNumberDecimalToString(produto.comprimento),
            multiplo_incremento: this.prepareNumberDecimalToString(
              produto.multiplo_incremento
            ),
            preco_embalagem: this.prepareNumberDecimalToString(
              produto.preco_embalagem
            ),
            quantidade_subitem_embalagem: this.prepareNumberDecimalToString(
              produto.quantidade_subitem_embalagem
            ),
            privado: false,
            metatags_seo: {
              description: _.get(produto, 'metatags_seo.description', ''),
              keywords: _.get(produto, 'metatags_seo.keywords', '')
            },
            tag_pesquisa: produto.tag_pesquisa || [],
            setores: (produto.setores || []).map(item => item.idsetor),
            caracteristicas: (produto.caracteristicas || []).map(item => ({
              ...item,
              id: `${item.idcaracteristica}-${Math.random() * 1000}`
            })),
            formasPagamentoCustomizadas: this.getDataFormasPagamentosCustomizadas(
              produto.formas_pagto_customizadas
            ),
            images: [],
            descricao_longa: produto.descricao_longa || '',
            permitir_alteracao_campos_erp: _.difference(
              this.form.camposIgnoraveisIntegracao,
              produto.ignorar_alteracao_erp
            ),
            maximo_disponivel: produto.maximo_disponivel
              ? this.prepareNumberDecimalToString(produto.maximo_disponivel)
              : null,
            max_por_pedido: produto.max_por_pedido
              ? this.prepareNumberDecimalToString(produto.max_por_pedido)
              : null,
            min_por_pedido: produto.min_por_pedido
              ? this.prepareNumberDecimalToString(produto.min_por_pedido)
              : null,
            carrossel: produto.carrossel || [],
            segmentoscliente: produto.segmentos_cliente
          }
          this.loadImages(idcadastroextraproduto)
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
          this.onClose()
        })
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      if (
        this.form.tag.includes('SOB_DEMANDA') &&
        (this.form.tag.includes('RECORRENCIA') ||
          this.form.tag.includes('AVULSO'))
      ) {
        this.$vueOnToast.pop(
          'error',
          'O produto não pode ser do tipo venda Avulso/Recorrência e ao mesmo tempo Sob Demanda!'
        )
        return
      }
      let produto = {
        ...this.form,
        tipoProduto: 'CONJUNTO',
        produto_em_uso: this.form.disponivel,
        esconderPrecoSubItem: !this.form.mostrarPrecoSubItens,
        subItens: [].concat(this.newSubItens).concat(this.form.subItens),
        formasPagamentoCustomizadas: this.getDataSubmitFormasPagamento(
          this.form.formasPagamentoCustomizadas
        ),
        descricoes_adicionais: this.form.descricoes_adicionais.filter(
          f => !!f.trim()
        ),
        idsetor: this.form.setores,
        multiplo_incremento: Math.abs(
          +this.prepareNumberDecimal(this.form.multiplo_incremento)
        ),
        id_imagem_produto: this.productImageId,
        granularidade: Math.abs(
          +this.prepareNumberDecimal(this.form.granularidade)
        ),
        peso: Math.abs(+this.prepareNumberDecimal(this.form.peso)),
        altura: Math.abs(+this.prepareNumberDecimal(this.form.altura)),
        largura: Math.abs(+this.prepareNumberDecimal(this.form.largura)),
        comprimento: Math.abs(
          +this.prepareNumberDecimal(this.form.comprimento)
        ),
        diametro: Math.abs(+this.prepareNumberDecimal(this.form.diametro)),
        quantidade_subitem_embalagem: Math.abs(
          +this.prepareNumberDecimal(this.form.quantidade_subitem_embalagem)
        ),
        preco_embalagem: this.form.permite_embalagem
          ? Math.abs(+this.prepareNumberDecimal(this.form.preco_embalagem))
          : null,
        ocultar_busca_principal: this.form.ocultar_busca_principal,
        tipounidade: this.form.unidade,
        maximo_disponivel: this.form.maximo_disponivel
          ? this.prepareNumberDecimal(this.form.maximo_disponivel)
          : null,
        max_por_pedido: this.form.max_por_pedido
          ? this.prepareNumberDecimal(this.form.max_por_pedido)
          : null,
        min_por_pedido: this.form.min_por_pedido
          ? this.prepareNumberDecimal(this.form.min_por_pedido)
          : null,
        tem_imagem: _.get(this.form, 'images', []).length ? true : false
      }
      if (produto.subItens && produto.subItens.length == 0) {
        this.$vueOnToast.pop(
          'error',
          'O conjunto deve conter ao menos um produto!'
        )
        return
      }
      if (
        !produto.id_produto_erp &&
        produto.codigobarras &&
        !isNaN(produto.codigobarras)
      ) {
        produto.id_produto_erp = produto.codigobarras
      }
      this.isLoading = true
      if (this.mode == 'edit' && produto.idproduto) {
        this.$store
          .dispatch(PRODUTO_UPDATE, produto)
          .then(() => {
            this.$vueOnToast.pop('success', 'Conjunto atualizado com sucesso')
            this.onClose(true)
          })
          .catch(() => (this.isLoading = false))
      } else {
        this.$store
          .dispatch(PRODUTO_CREATE, produto)
          .then(() => {
            this.$vueOnToast.pop('success', 'Conjunto criado com sucesso')
            this.onClose(true)
          })
          .catch(() => (this.isLoading = false))
      }
    },
    onClose(routeBack = false) {
      this.isLoading = false
      this.$emit('close')
      if (routeBack) {
        this.$router.replace('/fornecedor/comercial/conjunto-produtos')
      }
    },
    prepareNumberDecimal(number) {
      return number
        .toString()
        .replace(',', '.')
        .replace(/\s/g, '')
    },
    prepareNumberDecimalToString(number) {
      return `${(Number(number) || 0).toFixed(3)}`.replace('.', ',')
    },
    pesquisarCodigoBarrasExistente() {
      if (!this.form.codigobarras) return
      this.isLoading = true
      this.$store
        .dispatch(PRODUTO_LOAD_BY_COD_BARRAS, {
          codigobarras: this.form.codigobarras
        })
        .then(produto => {
          if (produto && produto.idproduto) {
            if (produto.idcadastroextraproduto) {
              this.mode = 'edit'
              this.loadImages(produto.idcadastroextraproduto)
            }
            this.form = {
              ...this.form,
              ...produto,
              unidade: produto.tipounidade,
              granularidade: this.prepareNumberDecimalToString(
                produto.granularidade
              ),
              peso: this.prepareNumberDecimalToString(produto.peso),
              altura: this.prepareNumberDecimalToString(produto.altura),
              largura: this.prepareNumberDecimalToString(produto.largura),
              diametro: this.prepareNumberDecimalToString(produto.diametro),
              comprimento: this.prepareNumberDecimalToString(
                produto.comprimento
              ),
              multiplo_incremento: this.prepareNumberDecimalToString(
                produto.multiplo_incremento
              ),
              quantidade_subitem_embalagem: this.prepareNumberDecimalToString(
                produto.quantidade_subitem_embalagem
              ),
              privado: false,
              metatags_seo: {
                description: _.get(produto, 'metatags_seo.description', ''),
                keywords: _.get(produto, 'metatags_seo.keywords', '')
              },
              tag_pesquisa: produto.tag_pesquisa || [],
              setores: (produto.setores || []).map(item => item.idsetor),
              caracteristicas: (produto.caracteristicas || []).map(item => ({
                ...item,
                id: `${item.idcaracteristica}-${Math.random() * 1000}`
              })),
              formasPagamentoCustomizadas: this.getDataFormasPagamentosCustomizadas(
                produto.formas_pagto_customizadas
              ),
              images: [],
              descricao_longa: produto.descricao_longa || '',
              carrossel: produto.carrossel || []
            }
          }
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    loadImages(idcadastroextraproduto) {
      this.imageLoading = true
      this.$store
        .dispatch(PRODUTO_LOAD_IMAGES, { idcadastroextraproduto })
        .then(respImg => {
          this.imageLoading = false
          this.form.images = _.get(respImg, 'imagens', [])
        })
        .catch(() => (this.imageLoading = false))
    },
    removeImage(imagem) {
      this.$store
        .dispatch(PRODUTO_REMOVE_IMAGE, {
          idcadastroextraproduto: this.productImageId,
          imagem
        })
        .then(() => {
          setTimeout(() => this.loadImages(this.productImageId), 500)
        })
    },
    showComponente(modulos = []) {
      if (modulos && modulos.length > 0) {
        return this.moduloFornecedorAtivo(modulos)
      } else {
        return false
      }
    },
    normalizeQuantity() {
      if (this.subItem.quantidade_itens < 0) {
        this.subItem.quantidade_itens = 0
      }
    },
    normalizePricefactor() {
      if (this.subItem.indice_preco < 0) {
        this.subItem.indice_preco = 0
      }
    },
    addProduct(subItem) {
      if (!subItem.idcadastroextraproduto) {
        this.$vueOnToast.pop('error', 'Selecionar o produto é obrigatório')
        return
      }
      if (!subItem.quantidade_itens) {
        this.$vueOnToast.pop('error', 'A quantidade é obrigatória')
        return
      }
      if (!subItem.indice_preco) {
        this.$vueOnToast.pop('error', 'O fator de preço é obrigatório')
        return
      }
      if (
        this.newSubItens.find(
          item => item.idcadastroextraproduto === subItem.idcadastroextraproduto
        ) !== undefined ||
        this.form.subItens.find(
          item => item.idcadastroextraproduto === subItem.idcadastroextraproduto
        ) !== undefined
      ) {
        this.$vueOnToast.pop('error', 'Produto já adicionado à este conjunto')
        return
      }
      this.newSubItens.push(subItem)
      this.subItem = {}
    },
    removeSubItem(subItem) {
      const options = {
        text: 'Essa ação é irreversível.',
        title: `Você confirma a exclusão do produto '${subItem.nome}'?`
      }
      this.confirmSwal(options, () => {
        this.$store
          .dispatch(PRODUTO_REMOVE_SUBITEM, subItem.idvinculoconjunto)
          .then(() => {
            this.form.subItens = this.form.subItens.filter(item => {
              return (
                item.idcadastroextraproduto !== subItem.idcadastroextraproduto
              )
            })
            this.$vueOnToast.pop(
              'success',
              'Produto removido do conjunto com sucesso'
            )
          })
          .catch(erro => {
            const msg = erro.Mensagem
              ? erro.Mensagem
              : 'Falha ao remover produto do conjunto'
            this.$vueOnToast.pop('error', msg)
            this.isLoading = false
          })
      })
    },
    removeNewSubItem(subItem) {
      const options = {
        text: 'Essa ação é irreversível.',
        title: `Você confirma a exclusão do produto '${subItem.nome}' do conjunto?`
      }
      this.confirmSwal(options, () => {
        this.newSubItens = this.newSubItens.filter(item => {
          return item.idcadastroextraproduto !== subItem.idcadastroextraproduto
        })
        this.$vueOnToast.pop(
          'success',
          'Produto removido do conjunto com sucesso'
        )
      })
    },
    validatePositiveNumber(value, field) {
      const number = value.replace(',', '.')
      parseFloat(number) < 0
        ? (this.form[field] = '0,000')
        : (this.form[field] = value)
    }
  },
  computed: {
    ...mapGetters([
      'getFornecedorId',
      'getFornecedorTag',
      'moduloFornecedorAtivo'
    ]),
    title() {
      return this.mode == 'edit' ? 'Editar conjunto' : 'Novo conjunto'
    },
    endpointTags() {
      return `/api/v4/tag-pesquisa/${this.getFornecedorId}`
    },
    endpointProdutos() {
      return `/api/v1/produtos/s2format-simple?idfornecedor=${this.getFornecedorId}`
    },
    uploadUrl() {
      return `/api/web/internal/v2/produto/${this.getFornecedorId}/${this.productImageId}/imagem`
    },
    productImageId() {
      let idcadastroextraproduto = 'novo-produto'
      if (this.form.idcadastroextraproduto) {
        idcadastroextraproduto = this.form.idcadastroextraproduto
      }
      return idcadastroextraproduto
    },
    disabledCodBarras() {
      return !this.form.privado && this.form.idproduto ? true : false
    },
    enableMaxLimitControl() {
      return this.getFornecedorTag['enable-max-limit-control']
    },
    enableMinLimitControl() {
      return this.getFornecedorTag['enable-min-limit-control']
    },
    enableCommerce() {
      return this.showComponente([
        ModulesEnum.WEB_COMMERCE,
        ModulesEnum.COMMERCE_APP,
        ModulesEnum.ORCAMENTO_APP,
        ModulesEnum.ORCAMENTO_WEB
      ])
    },
    enableVendaMais() {
      return this.showComponente([
        ModulesEnum.VENDAMAIS_WEB,
        ModulesEnum.VENDAMAIS_APP
      ])
    },
    endpointSegmentoCliente() {
      return `/api/v4/segmento-cliente/${this.getFornecedorId}`
    },
    showSubItens() {
      return (
        (this.form.subItens && this.form.subItens.length > 0) ||
        (this.newSubItens && this.newSubItens.length > 0)
      )
    }
  },
  created() {
    let { idproduto, idcadastroextraproduto } = this.$route.params
    if (idproduto !== 'novo') {
      this.mode = 'edit'
      this.getData(idproduto, idcadastroextraproduto)
    } else {
      this.editable = true
    }
  },
  watch: {
    'form.permitir_alteracao_campos_erp': {
      deep: true,
      handler(newVal) {
        this.form.ignorar_alteracao_erp = _.difference(
          this.form.camposIgnoraveisIntegracao,
          newVal
        )
      }
    }
  }
}
</script>
