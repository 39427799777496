export const ModulesEnum = {
  TODOS: 'Todos',
  ORCAMENTO_APP: 'Orçamento app',
  COMMERCE_APP: 'Commerce app',
  VENDAMAIS_APP: 'Venda+ app',
  ORCAMENTO_WEB: 'Orçamento web',
  WEB_COMMERCE: 'Web-commerce',
  VENDAMAIS_WEB: 'Venda+ web',
  PORTFOLIO_WEB: 'Portfolio web',
  PAINEL_CLIENTE: 'Painel de cliente'
}
