<template>
  <v-autocomplete
    v-model="model"
    :placeholder="placeholder || ''"
    :clearable="clearable"
    :no-filter="noFilter"
    :error-messages="errors"
    :multiple="multiple"
    :disabled="disabled"
    :readonly="readonly"
    :dark="darkEnabled"
    :items="options"
    :dense="dense"
    :label="label"
    :solo="solo"
    :loading="loading"
    outlined
    rounded
    :search-input.sync="search"
    flat
    :return-object="returnObject"
    @input="onChange"
    @blur="onBlur"
    :chips="chips"
    class="input-autocomplete"
  >
    <template v-slot:item="{ item }" v-if="useTemplate"
      ><slot name="item" :item="item">{{ item.text }}</slot></template
    >
  </v-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex'
import * as _ from 'lodash'
export default {
  name: 'FormAutocomplete',
  props: {
    options: {
      type: Array,
      default: () => [],
      required: false
    },
    value: {
      type: [Array, String, Object, Number, Boolean],
      default: '',
      required: false
    },
    label: {
      type: String,
      default: '',
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    },
    dense: {
      type: Boolean,
      default: true,
      required: false
    },
    solo: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false
    },
    clearable: {
      type: Boolean,
      default: false,
      required: false
    },
    noFilter: {
      type: Boolean,
      default: false,
      required: false
    },
    multiple: {
      type: Boolean,
      default: false,
      required: false
    },
    errorMessage: {
      type: [Object],
      default: () => ({}),
      required: false
    },
    validation: {
      type: Object,
      default: () => null,
      required: false
    },
    chips: {
      type: Boolean,
      default: false,
      required: false
    },
    useTemplate: {
      type: Boolean,
      default: false,
      required: false
    },
    returnObject: {
      type: Boolean,
      default: false,
      required: false
    },
    validar: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data: () => ({
    visibled: false,
    model: '',
    onSearchDebounced: null,
    search: ''
  }),
  created() {
    this.model = this.value
    this.onSearchDebounced = _.debounce(this.onSearch, 500)
  },
  watch: {
    value: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal != oldVal && newVal != this.model) {
          this.model = newVal
          this.$emit('deletedContent')
        }
      }
    },
    search: {
      handler(newVal, oldVal) {
        // TODO verificar motivo do search ficar limpando e repopulando sozinho
        const preventSearch =
          (this.model && this.model.value !== newVal) || !this.model
        if (newVal !== oldVal && preventSearch) {
          this.onSearchDebounced()
        }
      }
    },
    validation: {
      deep: true,
      handler(newVal) {
        this.touched = newVal.$dirty
      }
    }
  },
  computed: {
    ...mapGetters(['darkEnabled']),
    errors() {
      if (this.validation && (this.validation.$dirty || this.touched)) {
        let errorsMess = []
        if (
          this.validation.required != undefined &&
          !this.validation.required &&
          this.validar
        ) {
          errorsMess.push(this.errorMessage['required'] || 'Campo obrigatório')
        }
        return errorsMess
      }
      return []
    }
  },
  methods: {
    onChange(value) {
      this.$emit('change', value)
      this.$emit('input', value)
      this.checkValidation()
    },
    onBlur() {
      this.$emit('blur', this.model)
      this.checkValidation()
    },
    checkValidation() {
      if (this.validation) {
        this.validation.$touch()
        this.touched = true
      }
    },
    onSearch() {
      this.$emit('search', this.search || '')
    }
  }
}
</script>

<style>
.input-autocomplete {
  text-align: left;
}
</style>
