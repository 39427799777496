<template>
  <v-checkbox
    v-model="model"
    :disabled="disabled"
    :readonly="readonly"
    :label="label"
    :error-messages="errors"
    :value="val"
    :dark="darkEnabled"
    :messages="messages"
    color="primario"
    @change="onChange"
    :dense="true"
    @blur="onBlur"
  >
  </v-checkbox>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'FormCheckbox',
  props: {
    value: [String, Array, Boolean, Number, Object],
    val: {
      type: [String, Array, Boolean, Number, Object],
      default: () => false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false
    },
    label: {
      type: String,
      default: '',
      required: false
    },
    messages: {
      type: String,
      default: '',
      required: false
    },
    errorMessage: {
      type: [Object],
      default: () => ({}),
      required: false
    },
    validation: {
      type: Object,
      default: () => null,
      required: false
    }
  },
  data: () => ({
    model: ''
  }),
  watch: {
    value: {
      deep: true,
      handler() {
        this.initValue()
      }
    },
    validation: {
      deep: true,
      handler(newVal) {
        this.touched = newVal.$dirty
      }
    }
  },
  computed: {
    ...mapGetters(['darkEnabled']),
    errors() {
      if (this.validation && (this.validation.$dirty || this.touched)) {
        let errorsMess = []
        if (!this.validation.required) {
          errorsMess.push(this.errorMessage['required'] || 'Campo obrigatório')
        }
        return errorsMess
      }
      return []
    }
  },
  created() {
    this.initValue()
  },
  methods: {
    initValue() {
      if (Array.isArray(this.value)) {
        this.model = this.value.includes(this.val) ? this.val : ''
      } else {
        this.model = this.value
      }
    },
    onChange() {
      let result = null
      if (Array.isArray(this.value)) {
        result = this.value.filter(item => {
          return item != this.val
        })
        if (this.model) {
          result.push(this.val)
        }
      } else {
        result = this.model
      }
      this.$emit('change', result)
      this.$emit('input', result)
      this.checkValidation()
    },
    onBlur() {
      this.$emit('blur')
      this.checkValidation()
    },
    checkValidation() {
      if (this.validation) {
        this.validation.$touch()
        this.touched = true
      }
    }
  }
}
</script>
