<template>
  <v-col cols="12">
    <FormAutocomplete
      label="Categorias"
      placeholder="Pesquise aqui ou selecione categorias na árvore"
      multiple
      chips
      :options="setoresFolha"
      v-model="selecionados"
    />
    <v-treeview
      :items="itemsTree"
      open-on-click
      :load-children="loadChildren"
      transition
      dense
    >
      <template v-slot:label="{ item }">
        <v-checkbox
          class="seletor"
          :value="item.idsetor"
          v-model="selecionados"
          v-if="item.leaf"
          :label="item.name"
          dense
          light
        />
        <template v-else>{{ item.name }}</template>
      </template>
    </v-treeview>
  </v-col>
</template>

<script>
import FormAutocomplete from '@/components/form/FormAutocomplete'
import { SETOR_RAIZ_LOAD, SETROR_FILHO_LOAD } from '@/store/actions/setor'
import { mapGetters } from 'vuex'
export default {
  name: 'ArvoreCategoria',
  components: { FormAutocomplete },
  props: {
    value: {
      type: Array,
      default: () => [],
      required: false
    }
  },
  data: () => ({
    selecionados: [],
    itemsTree: [
      {
        id: -1,
        name: 'Categorias',
        children: [],
        leaf: false
      }
    ]
  }),
  watch: {
    getListSegmentos: {
      handler() {
        this.initTree()
      }
    },
    value: {
      handler(newval) {
        this.selecionados = newval
      }
    },
    selecionados: {
      handler(newval) {
        this.$emit('input', newval)
      }
    }
  },
  created() {
    this.initTree()
  },
  computed: {
    ...mapGetters([
      'getListSegmentos',
      'getIdListSegmentos',
      'getSetoresFolhas'
    ]),
    setoresFolha() {
      return this.getSetoresFolhas.reduce((folhas, folha) => {
        if (this.getIdListSegmentos.includes(folha.idsegmento)) {
          folhas.push({
            text: `${folha.segmento}/${folha.rotulo}`,
            value: folha.id
          })
        }
        return folhas
      }, [])
    }
  },
  methods: {
    async loadChildren(item) {
      try {
        let dados = []
        if (item.idsetor) {
          dados = await this.$store.dispatch(SETROR_FILHO_LOAD, {
            idsegmento: item.idsegmento,
            idsetor: item.idsetor
          })
        } else {
          dados = await this.$store.dispatch(SETOR_RAIZ_LOAD, item.idsegmento)
        }
        if (dados && dados.length > 0) {
          return (item.children = dados.map(subitem => {
            let node = {
              id: `${subitem.idsegmento}_${subitem.id}`,
              idsetor: subitem.id,
              idsegmento: subitem.idsegmento,
              name: subitem.rotulo,
              leaf: true
            }
            if (subitem.quantfilhos > 0) {
              node.children = []
              node.leaf = false
            }
            return node
          }))
        } else {
          item.leaf = true
          return item
        }
      } catch (err) {
        return []
      }
    },
    initTree() {
      if (this.getListSegmentos && this.getListSegmentos.length > 0) {
        this.itemsTree[0].children = this.getListSegmentos.map(item => ({
          id: item.idsegmento,
          idsegmento: item.idsegmento,
          name: item.nome,
          children: [],
          leaf: false
        }))
      }
    }
  }
}
</script>

<style lang="scss">
.seletor {
  margin: 0px !important;
  .v-input__slot {
    margin: 0px !important;
  }
  .v-messages {
    display: none !important;
  }
}
</style>
